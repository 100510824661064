import { DateTime, Interval } from 'luxon';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import * as utils from '@/components/WeekSelection/utils';
import { type CellCoords } from '@/components/WeekSelection/utils';
import { Header } from '@/components/WeekSelection/Header';
import { Row } from '@/components/WeekSelection/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Breakpoint } from '@/composables/breakpoints';
import _ from 'lodash';
import { type TranslationObject, useTranslation } from '@/composables/translation';
import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import { useForceUpdate } from '@/composables/forceUpdate';

enum SelectionMode {
    ADD = 'add',
    REMOVE = 'remove'
}

enum WeekLength {
    FULL = 7,
    HALF = 3
}

export interface IntervalObject extends IntervalStyle {
    type: TypeID;
    variant?: TypeID;
    interval: Interval;
    label?: TranslationObject | string;
    tooltip?: TranslationObject | string;
    zIndex?: number;
    nonClickable?: boolean;
    [key: string]: any;
}

export type TypeID = string | number;

export interface IntervalStyle {
    class?: string;
    style?: React.CSSProperties & Record<string, string>;
}

export interface IntervalID {
    id: TypeID;
    label?: TranslationObject | string;
}

export interface IntervalVariant extends IntervalStyle, IntervalID {
    tooltip?: TranslationObject | string;
    icon?: string;
}

export interface IntervalType extends IntervalStyle, IntervalID {
    tooltip?: TranslationObject | string;
    variants?: IntervalVariant[];
}

interface Props {
    startDate: DateTime;
    intervalMinutes: 15 | 30 | 45 | 60;
    dayStartHour: number;
    dayEndHour: number;
    value: IntervalObject[][];
    readonly?: boolean;
    weekLength?: number;
    debugDayStartUtc?: boolean;
    useTypeLabels?: boolean;
    intervalFilter?: (value: IntervalObject) => boolean;
    intervalTypes?: IntervalType[];
    currentIntervalType?: TypeID;
    currentIntervalVariant?: TypeID;
    breakpoint?: number | Breakpoint;
    headerClassName?: string;
    onChange?: (value: IntervalObject[][]) => void;
    onClickInterval?: (value: IntervalObject) => void;
}

export function WeekSelection(props: Props) {
    const filterIntervals = (i: IntervalObject) => {
        return props.intervalFilter?.(i) ?? true;
    };

    const dayStartHour = props.dayStartHour < props.dayEndHour
        ? _.clamp(props.dayStartHour, 0, 24)
        : _.clamp(props.dayEndHour, 0, 24);
    const dayEndHour = props.dayStartHour < props.dayEndHour
        ? _.clamp(props.dayEndHour, 0, 24)
        : _.clamp(props.dayStartHour, 0, 24);
    const dayStartMinute = dayStartHour * 60;
    const dayEndMinute = dayEndHour * 60;

    const start = props.startDate.startOf('week');
    const weekdays = [...Array(utils.NUM_WEEKDAYS)]
        .map((_, i) => start.plus({ days: i }));
    const numIntervalsPerHour = Math.floor(60 / props.intervalMinutes);
    const numIntervals = Math.floor((
        dayEndMinute - dayStartMinute
    ) / props.intervalMinutes);
    const hours = [...Array(numIntervals)]
        .map((_, i) =>
            DateTime.now()
                .startOf('day')
                .plus({ minutes: dayStartMinute + i * props.intervalMinutes })
        );
    const breakpoint = props.breakpoint ?? Breakpoint.MD;
    const intervalTypes =
        props.intervalTypes ?? [{ id: 1, class: 'tw-bg-lime-500/40' }];
    const intervalType = props.currentIntervalType ?? 1;
    const intervalVariant = props.currentIntervalVariant;
    const weekIntervalTypes = props.value
        .map(day => (day ?? [])
            .filter(filterIntervals)
            .reduce((types, int) => {
                types.add(int.type);
                return types;
            }, new Set<TypeID>())
        )
        .map(s => Array.from(s));
    const hourDayStart = DateTime.now()
        .toUTC()
        .startOf('day')
        .toLocal().hour;

    const { to } = useTranslation();
    const [dragRangeStart, setDragRangeStart] = useState<CellCoords>([0, 0]);
    const [dragRangeEnd, setDragRangeEnd] = useState<CellCoords>([0, 0]);
    const [dragging, setDragging] = useState(false);
    const [selectionMode, setSelectionMode] = useState(SelectionMode.ADD);
    const [weekStartIndex, setWeekStartIndex] = useState(0);
    const [weekLengthState, setWeekLengthState] = useState(
        window.innerWidth < breakpoint
            ? WeekLength.HALF
            : WeekLength.FULL
    );
    const [forceUpdate] = useForceUpdate();
    const weekLength = props.weekLength ?? weekLengthState;
    const firstCell = useRef<HTMLDivElement>(null);

    const handleMouseUp = () => {
        if (!dragging) return;
        setDragging(false);
        applyInterval(
            utils.beginCellRange(dragRangeStart, dragRangeEnd),
            utils.endCellRange(dragRangeStart, dragRangeEnd)
        );
    };

    const handleMouseMove = (mouseX: number, mouseY: number) => {
        if (!dragging) return;
        const currentCell = calcCellCoords(mouseX, mouseY);
        setDragRangeEnd(currentCell);
    };

    const handleMouseDown = (mouseX: number, mouseY: number) => {
        setDragging(true);
        const currentCell = calcCellCoords(mouseX, mouseY);
        setSelectionMode(
            isCellSelected(currentCell, intervalType)
                ? SelectionMode.REMOVE
                : SelectionMode.ADD
        );
        setDragRangeStart(currentCell);
        setDragRangeEnd(currentCell);
    };

    const calcCellCoords = (mouseX: number, mouseY: number): CellCoords => {
        const { x, y, width, height } = firstCell.current?.getBoundingClientRect() ?? {
            x: 0, y: 0, width: 0, height: 0
        };
        const coords: CellCoords = [
            Math.floor((
                mouseX - x
            ) / width),
            Math.floor((
                mouseY - y
            ) / height)
        ];
        return utils.clampCellCoords(coords, [0, 0], [weekdays.length - 1, numIntervals - 1]);
    };

    const convertToInterval = (dayCoord: number, beginCoord: number, endCoord: number) => {
        const day = weekdays[dayCoord];
        const startTime = day.plus({
            minutes: dayStartMinute + beginCoord * props.intervalMinutes
        });
        const endTime = startTime.plus({
            minutes: (
                endCoord - beginCoord + 1
            ) * props.intervalMinutes
        });
        return Interval.fromDateTimes(startTime, endTime);
    };

    const convertToRowCoord = (date: DateTime, day?: DateTime): number => {
        const dayStart = day ?? date.startOf('day');
        return _.clamp(date.diff(
            dayStart.plus({ minutes: dayStartMinute }),
            'minutes'
        ).minutes ?? 0, 0, dayEndMinute - dayStartMinute) / props.intervalMinutes;
    };

    const convertToCoords = (interval: Interval): [CellCoords, CellCoords] => {
        const weekday = (
            interval.start?.weekday ?? interval.end?.weekday ?? 1
        );
        const day = weekdays[weekday - 1];
        const start = convertToRowCoord(interval.start, day);
        const end = convertToRowCoord(interval.end, day);
        const startCoords: CellCoords = [
            weekday - weekStartIndex - 1,
            Math.floor(start)
        ];
        const endCoords: CellCoords = [
            weekday - weekStartIndex - 1,
            Math.floor(end)
        ];
        return [startCoords, endCoords];
    };

    const convertToDateTime = (coords: CellCoords) => {
        const day = weekdays[coords[0]];
        return day.plus({
            minutes: dayStartMinute + (coords[1] + 0.5) * props.intervalMinutes
        });
    };

    const isCellSelected = (coords: CellCoords, type: TypeID) => {
        const finalCoords: CellCoords = [coords[0] + weekStartIndex, coords[1]];
        const dateTime = convertToDateTime(finalCoords);
        return (
            props.value[finalCoords[0]] ?? []
        ).filter(i => i.type === type)
            .some(i => i.interval.contains(dateTime));
    };

    const removeInterval = (value: IntervalObject[], interval: Interval, type: TypeID) => {
        const [relevant, rest] = _.partition(value, i => i.type === type);
        const intervalType = intervalTypes.find(t => t.id === type);
        return ([...(intervalType?.variants ?? []).map(v => v.id), undefined])
            .reduce<IntervalObject[]>((arr, vid) => [
                ...arr,
                ...Interval.merge(
                    relevant.filter(i => i.variant === vid)
                        .map(i => i.interval.difference(interval))
                        .flat()
                ).map(i => ({
                    type,
                    variant: vid,
                    interval: i
                }))
            ], [])
            .concat(rest);
    };

    const addInterval = (
        value: IntervalObject[],
        interval: Interval,
        type: TypeID,
        variant?: TypeID
    ) => {
        const newValue = removeInterval(value, interval, type);
        const [relevant, rest] = _.partition(newValue, i => i.type === type);
        const intervalType = intervalTypes.find(t => t.id === type);
        return ([...(intervalType?.variants ?? []).map(v => v.id), undefined])
            .reduce<IntervalObject[]>((arr, vid) => [
                ...arr,
                ...Interval.merge(
                    relevant.filter(i => i.variant === vid)
                        .map(i => i.interval)
                        .concat(variant === vid ? [interval] : [])
                ).map(i => ({
                    type,
                    variant: vid,
                    interval: i
                }))
            ], [])
            .concat(rest);
    };

    const applyInterval = (begin: CellCoords, end: CellCoords) => {
        const newValue = [...props.value];
        for (let i = begin[0] + weekStartIndex; i <= end[0] + weekStartIndex; i++) {
            const interval = convertToInterval(i, begin[1], end[1]);
            if (selectionMode === SelectionMode.ADD) {
                newValue[i] = addInterval(
                    newValue[i] ?? [],
                    interval,
                    intervalType,
                    intervalVariant
                );
            } else if (selectionMode === SelectionMode.REMOVE) {
                newValue[i] = removeInterval(newValue[i] ?? [], interval, intervalType);
            }
        }
        props.onChange?.(newValue);
    };

    useEffect(() => {
        const handlerMouse = (e: MouseEvent) => {
            if (e.button === 0) {
                handleMouseUp();
            }
        };
        const handlerResize = () => {
            if (props.weekLength) {
                return;
            }
            const newWeekLength = window.innerWidth < breakpoint
                ? WeekLength.HALF
                : WeekLength.FULL;
            if (weekLength !== newWeekLength) {
                setWeekLengthState(newWeekLength);
                setWeekStartIndex(_.clamp(weekStartIndex, 0, utils.NUM_WEEKDAYS - newWeekLength));
            }
        };
        const handlerTouch = (e: TouchEvent) => {
            if (dragging) {
                e.preventDefault();
            }
        };
        window.addEventListener('mouseup', handlerMouse);
        window.addEventListener('resize', handlerResize);
        window.addEventListener('touchmove', handlerTouch, { passive: false });
        const interval = window.setInterval(() => forceUpdate(), 60000);
        return () => {
            window.removeEventListener('mouseup', handlerMouse);
            window.removeEventListener('resize', handlerResize);
            window.removeEventListener('touchmove', handlerTouch);
            window.clearInterval(interval);
        };
    });

    useEffect(() => {
        const index = props.startDate.weekday - 1;
        setWeekStartIndex(_.clamp(index, 0, utils.NUM_WEEKDAYS - weekLength));
    }, [props.startDate.toFormat('yyyy-MM-dd')]);

    const begin = utils.beginCellRange(dragRangeStart, dragRangeEnd);
    const end = utils.endCellRange(dragRangeStart, dragRangeEnd);
    const now = convertToRowCoord(DateTime.now());
    const nowIndex = Math.floor(now);
    const nowFraction = now - nowIndex;

    // NOTE: DO NOT REMOVE THIS, Tailwind compiles classes at runtime from source
    // (comments included)
    // tw-grid-cols-[100px_repeat(7,_minmax(0,_1fr))]
    // tw-grid-cols-[100px_repeat(3,_minmax(0,_1fr))]
    // tw-grid-cols-[100px_repeat(1,_minmax(0,_1fr))]
    return (
        <div
            className={`tw-grid tw-grid-cols-[100px_repeat(${weekLength},_minmax(0,_1fr))]`}
        >
            <div className={cn(
                'tw-border-solid tw-border-0 tw-border-b tw-border-black/30',
                'tw-flex tw-justify-center tw-items-center tw-gap-x-2'
            )}>
                {weekLength !== WeekLength.FULL && !props.weekLength &&
                 <>
                     <Button
                         variant="ghost"
                         style={{ height: 48, width: 48 }}
                         disabled={weekStartIndex === 0}
                         onClick={() => {
                             const value = _.clamp(
                                 weekStartIndex - WeekLength.HALF,
                                 0,
                                 utils.NUM_WEEKDAYS - WeekLength.HALF
                             );
                             setWeekStartIndex(value);
                         }}
                     >
                         <FontAwesomeIcon className="tw-text-base" icon={faAngleLeft} />
                     </Button>
                     <Button
                         variant="ghost"
                         style={{ height: 48, width: 48 }}
                         disabled={weekStartIndex + weekLength >= WeekLength.FULL}
                         onClick={() => {
                             const value = _.clamp(
                                 weekStartIndex + WeekLength.HALF,
                                 0,
                                 utils.NUM_WEEKDAYS - WeekLength.HALF
                             );
                             setWeekStartIndex(value);
                         }}
                     >
                         <FontAwesomeIcon className="tw-text-base" icon={faAngleRight} />
                     </Button>
                 </>
                }
            </div>
            {weekdays.slice(weekStartIndex, weekStartIndex + weekLength).map((date, i) =>
                <Header
                    key={i}
                    date={date}
                    selected={props.startDate.equals(date)}
                    className={props.headerClassName}
                />
            )}
            {hours.map((hour, i) =>
                <Row
                    key={i}
                    cellRef={i === 0 ? firstCell : undefined}
                    index={i + 2}
                    hour={hour}
                    numWeekdays={weekLength}
                    debugDayStartUtc={
                        props.debugDayStartUtc &&
                            i === hourDayStart * numIntervalsPerHour
                    }
                    dashed={i % numIntervalsPerHour !== numIntervalsPerHour - 1}
                />
            )}
            <div
                onMouseDown={(e) => {
                    if (e.button === 0) {
                        handleMouseDown(e.clientX, e.clientY);
                    }
                }}
                onTouchStart={e => handleMouseDown(e.touches[0].clientX, e.touches[0].clientY)}
                onTouchMove={e => handleMouseMove(e.touches[0].clientX, e.touches[0].clientY)}
                onTouchEnd={e => {
                    e.preventDefault();
                    handleMouseUp();
                }}
                onMouseMove={e => handleMouseMove(e.clientX, e.clientY)}
                className={cn(
                    'tw-select-none tw-cursor-pointer',
                    'tw-grid tw-grid-cols-[subgrid] tw-grid-rows-[subgrid]'
                )}
                style={{
                    zIndex: 1,
                    gridColumn: utils.toGridCol(0, weekdays.length),
                    gridRow: utils.toGridRow(0, numIntervals),
                    pointerEvents: props.readonly ? 'none' : undefined
                }}
            >
                {props.value.slice(weekStartIndex, weekStartIndex + weekLength).map((day, x) => {
                    if (!Array.isArray(day)) {
                        return null;
                    }
                    return (
                        <Fragment key={x}>{day
                            .filter(filterIntervals)
                            .map((interval, y) => {
                                const [begin, end] = convertToCoords(interval.interval);
                                const intervalType = intervalTypes.find(
                                    t => t.id === interval.type
                                );
                                const intervalVariant = intervalType?.variants
                                    ?.find(v => v.id === interval.variant);
                                const dayIntervalTypes =
                                    weekIntervalTypes[x + weekStartIndex] ?? [];
                                const intervalIndex = dayIntervalTypes.findIndex(
                                    t => t === interval.type
                                );
                                return (
                                    <div
                                        key={`${x}_${y}`}
                                        className={cn(
                                            'tw-grid tw-grid-rows-1 tw-pointer-events-none',
                                            'tw-p-[2px]',
                                            {
                                                'tw-cursor-pointer': !interval.nonClickable,
                                                '!tw-cursor-default': interval.nonClickable
                                            }
                                        )}
                                        style={{
                                            gridTemplateColumns:
                                            `repeat(${dayIntervalTypes.length}, 1fr)`,
                                            zIndex: interval.zIndex,
                                            gridColumn: utils.toGridCol(
                                                begin[0] + 1,
                                                end[0] + 1,
                                                false
                                            ),
                                            gridRow: utils.toGridRow(
                                                begin[1] + 1,
                                                end[1] + 1,
                                                false
                                            )
                                        }}
                                        onMouseDown={(e) => {
                                            if (
                                                !!props.onClickInterval ||
                                                !!props.readonly
                                            ) {
                                                e.stopPropagation();
                                            }
                                        }}
                                        onTouchStart={(e) => {
                                            if (
                                                !!props.onClickInterval ||
                                                !!props.readonly
                                            ) {
                                                e.stopPropagation();
                                            }
                                        }}
                                        onTouchEnd={(e) => {
                                            e.stopPropagation();
                                            if (!interval.nonClickable) {
                                                props.onClickInterval?.(interval);
                                            }
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (!interval.nonClickable) {
                                                props.onClickInterval?.(interval);
                                            }
                                        }}
                                    >
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <div
                                                    className={cn(
                                                        'tw-h-full',
                                                        'tw-select-none tw-pointer-events-auto',
                                                        'tw-flex tw-flex-col tw-justify-center',
                                                        intervalType?.class,
                                                        intervalVariant?.class,
                                                        interval.class
                                                    )}
                                                    style={{
                                                        ...intervalType?.style,
                                                        ...intervalVariant?.style,
                                                        ...interval.style,
                                                        gridColumn:
                                                        `${intervalIndex + 1} / ${intervalIndex + 1}`
                                                    }}
                                                >
                                                    <div className="tw-truncate tw-text-center">
                                                        {to(
                                                            interval?.label ??
                                                            (
                                                                props.useTypeLabels
                                                                    ? intervalType?.label
                                                                    : intervalVariant?.label ??
                                                                    intervalType?.label
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </TooltipTrigger>
                                            <TooltipContent className="tw-z-50">
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: to(
                                                            interval?.tooltip ??
                                                            intervalVariant?.tooltip ??
                                                            intervalType?.tooltip
                                                        )
                                                    }}
                                                />
                                            </TooltipContent>
                                        </Tooltip>
                                    </div>
                                );
                            })}
                        </Fragment>
                    );
                })}
            </div>
            <div
                className="tw-z-[5] tw-relative tw-pointer-events-none tw-overflow-visible"
                style={{
                    gridColumn: '2 / -1',
                    gridRow: utils.toGridRow(nowIndex, nowIndex)
                }}
            >
                <div
                    className={cn(
                        'tw-absolute tw-left-[-4.5px] tw-size-[8px] tw-translate-y-[-3.5px]',
                        'tw-bg-primary tw-rounded-full'
                    )}
                    style={{ top: `${nowFraction * 100}%` }}
                />
                <div
                    className="tw-absolute tw-inset-x-0 tw-border-dashed tw-border-b-[2px] tw-border-primary"
                    style={{ top: `${nowFraction * 100}%` }}
                />
            </div>
            <div
                className={cn({
                    'tw-bg-yellow-500/40': selectionMode === SelectionMode.ADD,
                    'tw-bg-red-500/40': selectionMode === SelectionMode.REMOVE
                })}
                style={{
                    zIndex: 0,
                    ...(
                        dragging && {
                            gridColumn: utils.toGridCol(begin[0], end[0] + 1),
                            gridRow: utils.toGridRow(begin[1], end[1] + 1)
                        }
                    )
                }}
            />
        </div>
    );
}
