import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import { updatePassword } from '@/composables/api';
import { useError } from '@/composables/error';
import { useValidation } from '@/composables/validation';
import { useTranslation } from '@/composables/translation';
import { Button } from '@/components/ui/button';
import { ButtonSubmit } from '@/components/ui/button-submit';
import { CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { useForm } from 'react-hook-form';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import PasswordValidator from '@/components/PasswordValidator';

export function ChangePassword() {
    const { t } = useTranslation('settings.password');
    const error = useError();
    const { required, passwordMatches, passwordSecure } = useValidation();

    const defaultValues = {
        password: '',
        new_password: '',
        new_password2: ''
    };
    const form = useForm({
        defaultValues
    });
    const pwdRef = useRef('');

    function handleSubmit({ password, new_password }: typeof defaultValues) {
        const isSecure = passwordSecure(new_password);
        if (isSecure === true) {
            return updatePassword(password, new_password)
                .then((res) => {
                    form.reset();
                    toast(
                        res.data.message,
                        { type: res.data.updated ? 'success' : 'warning' }
                    );
                })
                .catch(error.handleNetworkError);
        }
        return toast.error(isSecure);
    }

    return (
        <>
            <CardHeader>
                <CardTitle>{t('title')}</CardTitle>
            </CardHeader>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                    <CardContent className="tw-flex tw-flex-col tw-gap-3">
                        <FormField
                            name="password"
                            rules={{ validate: { required } }}
                            render={({ field }) =>
                                <FormItem className="tw-flex-1">
                                    <FormLabel>{t('current-password')}</FormLabel>
                                    <FormControl>
                                        <Input
                                            id="password"
                                            type="password"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            }
                        />
                        <FormField
                            name="new_password"
                            rules={{ validate: { required } }}
                            render={({ field }) =>
                                <FormItem className="tw-flex-1">
                                    <FormLabel>{t('new-password')}</FormLabel>
                                    <FormControl>
                                        <Input
                                            id="new_password"
                                            type="password"
                                            {...field}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                pwdRef.current = e.target.value;
                                            }}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                    <PasswordValidator password={field?.value}/>
                                </FormItem>
                            }
                        />
                        <FormField
                            name="new_password2"
                            rules={{ validate: { required, pwd: passwordMatches(pwdRef) } }}
                            render={({ field }) =>
                                <FormItem className="tw-flex-1">
                                    <FormLabel>{t('confirm-password')}</FormLabel>
                                    <FormControl>
                                        <Input
                                            id="new_password2"
                                            type="password"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            }
                        />
                    </CardContent>
                    <CardFooter className="tw-flex-col tw-gap-2">
                        <Button variant="link" className="!tw-px-0 tw-self-start" asChild>
                            <Link to="/settings/account">
                                {t('return-to-settings')}
                            </Link>
                        </Button>
                        <ButtonSubmit className="tw-self-stretch">
                            {t('submit')}
                        </ButtonSubmit>
                    </CardFooter>
                </form>
            </Form>
        </>
    );
}
