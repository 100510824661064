import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { type Paths } from '@/types/utils';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { type TranslateObjectFn, type TranslationObject, useTranslation } from '@/composables/translation';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Combobox, type ComboboxProps } from '@/components/ui/combobox';
import { TextFieldTranslate } from '@/components/TextFieldTranslate';
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { useForm, useFormContext, type UseFormReturn, type Validate } from 'react-hook-form';
import { type ColumnDef, type RowSelectionState, type CellContext } from '@tanstack/react-table';
import { DataTable, type DataTableColumn, type DataTableProps } from '@/components/ui/data-table';
import { MagnifyingGlassIcon, Pencil2Icon, PlusIcon, TrashIcon } from '@radix-ui/react-icons';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { type FieldValues, type UseFormStateReturn } from 'react-hook-form/dist/types';
import { clone, removeAccents } from '@/composables/utils';
import { type ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { getFieldError, useValidation } from '@/composables/validation';
import { ButtonSubmit } from '@/components/ui/button-submit';
import _ from 'lodash';
import { Col, Row } from '@/components/ui/row';
import { useError } from '@/composables/error';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import { type TreeNode, TreeViewSelect } from '@/components/TreeViewSelect';
import { FillFlexParent } from '@/components/utils/FlexFillParent';
import { Checkbox } from '@/components/ui/checkbox';
import { useControllableState } from '@/composables/controllable';
import { InputNumber } from '@/components/ui/input-number';
import { cn } from '@/lib/utils';
import type { FactoryOpts } from 'imask';
import { useIMask } from 'react-imask';
import { DateTime } from 'luxon';
import { DatePicker } from '@/components/ui/date-picker';
import { useDebounced } from '@/composables/debounce';
import { type FilterFnOption } from '@tanstack/table-core/src/features/Filters';
import { TimePicker } from '@/components/ui/time-picker';

export enum CrudInputType {
    TEXT = 'text',
    SELECT = 'select',
    NUMBER = 'number',
    CHECKBOX = 'checkbox',
    TREE = 'tree',
    DATE = 'date',
    TIME = 'time',
    HEADER = 'header'
}

export interface CrudInputBaseOptions<TValue> {
    id: Paths<TValue> | string;
    type: CrudInputType;
    col?: number;
    name?: TranslationObject | string;
    label?: boolean;
    readonly?: boolean;
    required?: boolean;
    create?: boolean;
    update?: boolean;
    defaultValue?: unknown;
    onChange?: (form: UseFormReturn, value: any) => void;
    validate?: Record<string, Validate<any, any>>;
}

export interface CrudTextInputOptions<TValue> extends CrudInputBaseOptions<TValue> {
    type: CrudInputType.TEXT;
    translate?: boolean;
    mask?: FactoryOpts;
}

export interface CrudCheckboxInputOptions<TValue> extends CrudInputBaseOptions<TValue> {
    type: CrudInputType.CHECKBOX;
    hideLabel?: boolean;
}

export interface CrudNumberInputOptions<TValue> extends CrudInputBaseOptions<TValue> {
    type: CrudInputType.NUMBER;
    min?: number;
    max?: number;
    scale?: number;
    radix?: string;
    thousandsSeparator?: string;
}

export interface CrudSelectInputOptions<TValue, TOptions, TId = string> extends CrudInputBaseOptions<TValue> {
    type: CrudInputType.SELECT;
    options?: TOptions[];
    getOptionsAsync?: ComboboxProps<TOptions, TId>['getOptionsAsync'];
    groupBy?: ComboboxProps<TOptions, TId>['groupBy'];
    getOptionLabel?: ComboboxProps<TOptions, TId>['getOptionLabel'];
    getOptionValue?: ComboboxProps<TOptions, TId>['getOptionValue'];
    getValueLabel?: ComboboxProps<TOptions, TId>['getValueLabel'];
    multiple?: boolean;
    clearable?: boolean;
    creatable?: boolean;
}

export interface CrudTreeInputOptions<TValue> extends CrudInputBaseOptions<TValue> {
    type: CrudInputType.TREE;
    nodes: TreeNode[];
    maximal?: boolean;
}

export interface CrudDateInputOptions<TValue> extends CrudInputBaseOptions<TValue> {
    type: CrudInputType.DATE;
}

export interface CrudTimeInputOptions<TValue> extends CrudInputBaseOptions<TValue> {
    type: CrudInputType.TIME;
}

export interface CrudHeaderInputOptions<TValue> extends CrudInputBaseOptions<TValue> {
    type: CrudInputType.HEADER;
}

export interface CrudInputExtraOptions<TValue> {
    columnDef?: ColumnDef<TValue>;
}

export type CrudInputOptions<TValue, TOptions = any, TId = any> =
    (
        CrudTextInputOptions<TValue> |
        CrudNumberInputOptions<TValue> |
        CrudSelectInputOptions<TValue, TOptions, TId> |
        CrudCheckboxInputOptions<TValue> |
        CrudTreeInputOptions<TValue> |
        CrudDateInputOptions<TValue> |
        CrudTimeInputOptions<TValue> |
        CrudHeaderInputOptions<TValue>
    );

export type CrudSchemaOptions<TValue, TOptions = any, TId = any> =
    CrudInputOptions<TValue, TOptions, TId> & CrudInputExtraOptions<TValue>;

export type CrudSchema<TValue> = Array<CrudSchemaOptions<TValue>>;

export interface CrudTableProps<TValue, TIdKey extends Paths<TValue>>
    extends Partial<DataTableProps<TValue, unknown>> {
    apiRef?: React.MutableRefObject<CrudApi | undefined>;
    idKey: TIdKey;
    schema: CrudSchema<TValue>;
    initialData?: TValue[];
    actions?: (props: CellContext<TValue, unknown>) => React.ReactNode;
    toolbar?: React.ReactNode;
    createButton?: React.FC<{ disabled: boolean; onClick: () => void }>;
    dialogComponent?: React.FC<UpdateDialogProps<TValue>>;
    list?: TValue[];
    onChangeList?: React.Dispatch<React.SetStateAction<TValue[]>>;
    onCreate?: (value: Omit<TValue, TIdKey>) => Promise<void>;
    onRead?: (query: string) => Promise<TValue[]>;
    onUpdate?: (value: TValue) => Promise<void>;
    onDelete?: (values: TValue[]) => Promise<void>;
    readDeps?: any[];
    useServerSearch?: boolean;
}

export interface UpdateDialogProps<TValue> {
    value?: TValue;
    open?: boolean;
    isUpdateDialog?: boolean;
    translation?: {
        title?: TranslationObject;
        cancel?: TranslationObject;
        submit?: TranslationObject;
    };
    onOpenChange?: React.Dispatch<boolean>;
    onSubmit: (value: TValue) => Promise<void>;
}

export interface CrudApi {
    refreshList: () => void;
    showCreateDialog: () => void;
    setSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
}

export interface CrudDialogOptions<TValue, TIdKey extends Paths<TValue>> {
    idKey: TIdKey;
    schema: CrudSchema<TValue>;
}

export function InputField<TValue extends FieldValues>(props: CrudInputOptions<TValue> & {
    field: ControllerRenderProps<TValue>;
    formState?: UseFormStateReturn<TValue>;
}) {
    const { t, to } = useTranslation('data-table');
    const form = useFormContext();
    function onChange(value: any) {
        props.field.onChange(value);
        props.onChange?.(form, value);
    }
    const error = props.formState ? !!getFieldError(props.id, props.formState) : false;
    switch (props.type) {
    case CrudInputType.TEXT: {
        const mask = props.mask
            ? useIMask(
                props.mask,
                {
                    onAccept: (value) => {
                        props.field.onChange(value);
                    }
                }
            )
            : undefined;
        if (props.mask && props.field.value !== mask?.value && !!props.field.value) {
            mask?.setValue(props.field.value);
        }
        return React.createElement(
            (
                props.translate ? TextFieldTranslate : Input
            ) as typeof Input,
            {
                id: props.id,
                ...props.field,
                ref: (ref) => {
                    if (mask) {
                        mask.ref.current = ref;
                    }
                    props.field.ref(ref);
                },
                value: props.field.value ?? '',
                onChange: (e) => {
                    const input = e.target;
                    if (mask) {
                        mask.setValue(input.value);
                    } else {
                        props.field.onChange(e);
                    }
                },
                disabled: props.readonly,
                error
            }
        );
    }
    case CrudInputType.CHECKBOX:
        return (
            <div className="tw-flex tw-items-center tw-py-1">
                <Checkbox
                    id={props.id}
                    {...props.field}
                    checked={props.field.value ?? false}
                    onCheckedChange={(value) => onChange(Boolean(value))}
                />
                {!props.hideLabel &&
                    <Label className="tw-pl-2" htmlFor={props.id}>
                        {to(props.name)}
                    </Label>
                }
            </div>
        );
    case CrudInputType.NUMBER:
        return (
            <InputNumber
                id={props.id}
                {...props.field}
                disabled={props.readonly}
                error={error}
            />
        );
    case CrudInputType.SELECT: {
        const {
            ref,
            ...field
        } = props.field;
        return (
            <Combobox
                id={props.id}
                className="!tw-flex tw-w-full"
                options={props.options}
                getOptionsAsync={props.getOptionsAsync}
                getOptionValue={props.getOptionValue}
                getOptionLabel={props.getOptionLabel}
                getValueLabel={props.getValueLabel}
                groupBy={props.groupBy}
                multiple={props.multiple}
                clearable={props.clearable}
                creatable={props.creatable}
                {...field}
                onChange={onChange}
                innerRef={ref}
                disabled={props.readonly}
                error={error}
            />
        );
    }
    case CrudInputType.TREE: {
        const {
            ref,
            ...field
        } = props.field;
        const [search, setSearch] = useState('');
        return (
            <div className="tw-flex tw-flex-col tw-h-[300px] tw-gap-2">
                <Input
                    className="tw-w-full"
                    placeholder={t('filter')}
                    prependIcon={<MagnifyingGlassIcon />}
                    prependBorder={false}
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                />
                <FillFlexParent>
                    {dimensions =>
                        <TreeViewSelect
                            nodes={props.nodes}
                            maximal={props.maximal}
                            searchTerm={search}
                            {...field}
                            value={field.value ?? []}
                            onChange={onChange}
                            {...dimensions}
                        />
                    }
                </FillFlexParent>
            </div>
        );
    }
    case CrudInputType.DATE: {
        if (typeof props.field.value === 'string') {
            props.field.value = DateTime.fromISO(props.field.value) as any;
            onChange(props.field.value);
        }
        return (
            <DatePicker
                {...props.field}
                disabled={props.readonly}
                error={error}
            />
        );
    }
    case CrudInputType.TIME: {
        if (typeof props.field.value === 'string') {
            props.field.value = DateTime.fromISO(props.field.value) as any;
            onChange(props.field.value);
        }
        return (
            <TimePicker
                {...props.field}
                disabled={props.readonly}
                error={error}
            />
        );
    }
    case CrudInputType.HEADER:
        return (<div className="tw-font-medium tw-text-primary">{to(props.name)}</div>);
    }
    return null;
}

export function FormInput<TValue extends FieldValues>(
    props: CrudInputOptions<TValue>
) {
    const { to } = useTranslation();
    const { required, requiredTranslate } = useValidation();
    const translate = (
        props as CrudTextInputOptions<TValue>
    ).translate;
    const label = props.label ?? ![CrudInputType.CHECKBOX, CrudInputType.HEADER].includes(props.type);
    const isHeader = props.type === CrudInputType.HEADER;
    return (
        <FormField
            name={props.id}
            defaultValue={props.defaultValue}
            render={({ field, formState }) =>
                <Col col={props.col} className={cn(isHeader && '[&:has(+_div)]:tw-mb-[-0.25rem]')}>
                    <FormItem>
                        {label &&
                            <FormLabel htmlFor={props.id}>
                                {to(props.name)}
                            </FormLabel>
                        }
                        <FormControl>
                            <InputField<TValue>
                                {...props}
                                field={field as any}
                                formState={formState as any}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                </Col>
            }
            rules={{
                validate: {
                    ...(props.required && !translate && { required }),
                    ...(props.required && translate && { requiredTranslate }),
                    ...props.validate
                }
            }}
        />
    );
}

export function CrudDialog<TValue extends FieldValues, TIdKey extends Paths<TValue>>(
    opts: CrudDialogOptions<TValue, TIdKey>
): React.FC<UpdateDialogProps<TValue>> {
    const fields = opts.schema;
    const createFields = fields.filter((f) => {
        if (f.id === opts.idKey) {
            return (f.create ?? false);
        }
        return (f.create ?? true);
    });
    const updateFields = fields.filter((f) => f.update ?? true);
    return function UpdateDialog(props: UpdateDialogProps<TValue>) {
        const { ct, to } = useTranslation();
        const form = useForm<TValue>({
            defaultValues: props.value as any
        });
        const isUpdateDialog = props.isUpdateDialog ?? false;
        const dialogFields = isUpdateDialog ? updateFields : createFields;
        useEffect(() => {
            if (props.value) {
                form.reset(props.value);
            } else {
                form.reset();
            }
        }, [props.value]);
        return (
            <Dialog open={props.open} onOpenChange={props.onOpenChange}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>
                            {to(props.translation?.title, isUpdateDialog ? ct('edit') : ct('create'))}
                        </DialogTitle>
                    </DialogHeader>
                    <Form {...form}>
                        <form onSubmit={(event) => {
                            event.stopPropagation();
                            form.handleSubmit(props.onSubmit)(event);
                        }}>
                            <Row className="tw-gap-y-3 tw-pb-6">
                                {dialogFields.map((options) =>
                                    <FormInput
                                        key={options.id}
                                        {...options}
                                    />
                                )}
                            </Row>
                            <DialogFooter>
                                <DialogClose asChild>
                                    <Button type="button" variant="secondary">
                                        {to(props.translation?.cancel, ct('cancel'))}
                                    </Button>
                                </DialogClose>
                                <ButtonSubmit>
                                    {to(props.translation?.submit, isUpdateDialog ? ct('edit') : ct('create'))}
                                </ButtonSubmit>
                            </DialogFooter>
                        </form>
                    </Form>
                </DialogContent>
            </Dialog>
        );
    };
}

function getColumnFilter<TValue extends object>(
    field: CrudSchemaOptions<TValue>
): CrudInputOptions<TValue> | undefined {
    if (field.type === CrudInputType.TREE) {
        return undefined;
    }
    return {
        ...field,
        id: `${field.id}-filter`,
        ...(field.type === CrudInputType.TEXT && { translate: false }),
        ...(field.type === CrudInputType.CHECKBOX && { hideLabel: true }),
        ...(field.type === CrudInputType.SELECT && { clearable: false })
    };
}

function getColumnFilterFn<TValue extends object>(
    field: CrudSchemaOptions<TValue>,
    to: TranslateObjectFn
): FilterFnOption<TValue> | undefined {
    switch (field.type) {
    case CrudInputType.TEXT:
        return (row, id, filterValue) => {
            const value = removeAccents(to(row.getValue(id))).toLowerCase();
            return value.includes(filterValue ?? '');
        };
    case CrudInputType.SELECT:
        return field.multiple ? 'arrIncludesAll' : 'includesString';
    case CrudInputType.CHECKBOX:
        return (row, id, filterValue) => {
            return filterValue === Boolean(row.getValue(id));
        };
    }
    return undefined;
}

export function CrudTable<TValue extends object, TIdKey extends Paths<TValue>>(
    {
        apiRef,
        idKey,
        schema,
        actions,
        toolbar,
        list,
        onChangeList,
        onCreate,
        onRead,
        onUpdate,
        onDelete,
        useServerSearch,
        ...props
    }: CrudTableProps<TValue, TIdKey>
) {
    const { ct, to } = useTranslation();
    const { handleNetworkError } = useError();

    const [loading, setLoading] = useState(false);
    const [selection, setSelection] = useState<RowSelectionState>({});
    const [data, setData] = useControllableState<TValue[]>([], list, onChangeList);
    const [showCreate, setShowCreate] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [updateValue, setUpdateValue] = useState<TValue | null>(null);
    const [globalFilter, setGlobalFilter] = useState('');
    const [query, setQuery] = useState('');
    const setQueryDebounced = useDebounced({ func: setQuery, timeout: 1000 });

    useEffect(() => {
        if (!globalFilter) {
            setQuery('');
            setQueryDebounced.cancel();
            return;
        }
        setQueryDebounced(globalFilter);
    }, [globalFilter]);

    useImperativeHandle(apiRef, () => ({
        refreshList: () => getData(globalFilter),
        showCreateDialog: () => setShowCreate(true),
        setSelection
    }), [setShowCreate, setSelection]);

    const columns = useMemo<Array<ColumnDef<TValue>>>(
        () => [
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            ...((actions || onUpdate)
                ? [{
                    id: 'actions',
                    header: 'Actions',
                    maxSize: 150,
                    cell: (props) => <div className="tw-flex">
                        {onUpdate && <Tooltip>
                            <TooltipTrigger asChild>
                                <Button
                                    type="button"
                                    variant="ghost" size="icon"
                                    onClick={() => {
                                        setUpdateValue(clone(props.row.original));
                                        setShowUpdate(true);
                                    }}
                                >
                                    <Pencil2Icon className="tw-size-4" />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                                {ct('edit')}
                            </TooltipContent>
                        </Tooltip>}
                        {actions?.(props)}
                    </div>,
                    enableSorting: false,
                    enableHiding: false
                } as ColumnDef<TValue>]
                : []),
            ...schema.filter((f) => !!f.columnDef)
                .map((f) => ({
                    ...f.columnDef,
                    filter: getColumnFilter(f),
                    filterFn: getColumnFilterFn(f, to)
                }) as DataTableColumn<TValue>)
        ],
        [schema]
    );
    const selectionList = useMemo(
        () => Object.keys(selection),
        [selection]
    );

    function getData(query: string) {
        setLoading(true);
        if (onRead) {
            onRead(query)
                .then((list) => setData(list))
                .catch(handleNetworkError)
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }

    useEffect(
        () => {
            getData(query);
        },
        [
            ...(props.manualPagination
                ? [
                    props.state?.pagination?.pageSize,
                    props.state?.pagination?.pageIndex
                ]
                : []
            ),
            ...(useServerSearch ? [query] : []),
            ...(props.readDeps ?? [])
        ]
    );

    const DialogComponent = useMemo(
        () => props.dialogComponent ?? CrudDialog({ idKey, schema }),
        [props.dialogComponent, schema, idKey]
    );

    async function handleDelete() {
        const list = data.filter(
            (value) => selectionList.includes(
                props.getRowId
                    ? props.getRowId(value, 0)
                    : _.get(value, idKey as string)
            )
        );
        try {
            await onDelete?.(list);
            setSelection({});
            getData(globalFilter);
        } catch (err) {
            handleNetworkError(err);
        }
    }

    return (
        <>
            <DataTable
                {...props}
                columns={columns}
                data={data}
                onChangeData={onChangeList}
                state={{
                    ...props.state,
                    globalFilter,
                    rowSelection: selection
                }}
                onRowSelectionChange={setSelection}
                onGlobalFilterChange={setGlobalFilter}
                loading={loading}
                getRowId={props.getRowId ?? ((row) => _.get(row, idKey as string))}
                enableRowSelection={!!onDelete}
                slots={{
                    header: <div className="tw-flex tw-gap-x-2 tw-flex-1 empty:tw-hidden">
                        {toolbar}
                        {onCreate && (props.createButton
                            ? props.createButton({
                                disabled: loading,
                                onClick: () => setShowCreate(true)
                            })
                            : <Button
                                disabled={loading} type="button"
                                variant="outline" className="tw-text-success"
                                onClick={() => setShowCreate(true)}
                            >
                                <PlusIcon className="tw-mr-2"/>
                                {ct('create')}
                            </Button>)}
                        {onDelete && <Button
                            type="button"
                            disabled={loading || selectionList.length <= 0}
                            variant="outline" className="tw-text-danger"
                            spinnerClassName="tw-text-primary tw-mx-3"
                            onClick={() => setShowDelete(true)}
                        >
                            <TrashIcon className="tw-mr-2"/>
                            {ct('remove')} {selectionList.length > 0 &&
                            `(${selectionList.length})`}
                        </Button>}
                    </div>
                }}
            />
            <DialogComponent
                open={showCreate}
                onOpenChange={setShowCreate}
                onSubmit={async(value) => {
                    try {
                        await onCreate?.(value as any);
                        setShowCreate(false);
                        getData(globalFilter);
                    } catch (err) {
                        handleNetworkError(err);
                    }
                }}
            />
            <DialogComponent
                value={updateValue as any ?? undefined}
                open={showUpdate}
                isUpdateDialog
                onOpenChange={setShowUpdate}
                onSubmit={async(value) => {
                    try {
                        await onUpdate?.(value as any);
                        setShowUpdate(false);
                        getData(globalFilter);
                    } catch (err) {
                        handleNetworkError(err);
                    }
                }}
            />
            <ConfirmDialog
                open={showDelete}
                title="Delete"
                message="Are you sure you want to delete?"
                confirmText={ct('remove')}
                onOpenChange={setShowDelete}
                onConfirm={handleDelete}
            />
        </>
    );
}
