import React, { useCallback, useRef, type ReactNode } from 'react';
import { useDropzone } from 'react-dropzone';
import type { DropEvent, FileRejection } from 'react-dropzone';
import { toast } from 'react-toastify';
import { useTranslation } from '@/composables/translation';
interface DropZoneWrapperProps {
    children: ReactNode;
    handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
    hoverBorder?: boolean;
}

const DropZoneWrapper: React.FC<DropZoneWrapperProps> = ({
    children,
    handleFileUpload,
    hoverBorder
}) => {
    const fileInput = useRef<HTMLInputElement | null>(null);
    const { t } = useTranslation('validation');
    const onDropAccepted = (acceptedFiles: File[]) => {
        const inputEvent = {
            target: {
                files: acceptedFiles
            }
        } as unknown as React.ChangeEvent<HTMLInputElement>;
        handleFileUpload(inputEvent);
    };

    const onDropRejected = useCallback((rejectedFiles: FileRejection[], event: DropEvent) => {
        rejectedFiles.forEach((fileRejection) => {
            const { errors, file } = fileRejection;
            errors.forEach((error) => {
                if (error.code === 'file-invalid-type') toast.error(`${t('invalid-file-type')} (${file?.type})`);
                else toast.error(`${t('invalid-file-unknown-error')} (${file.name})`);
            });
        });
    }, []);

    const acceptedFileTypes = {
        'image/jpeg': ['.jpeg', '.jpg'],
        'image/png': ['.png'],
        'application/pdf': ['.pdf'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        'message/rfc822': ['.eml'],
        'application/vnd.ms-outlook': ['.msg']
    };

    const { getRootProps, getInputProps, isDragActive, isFocused } = useDropzone({
        accept: acceptedFileTypes,
        onDropAccepted,
        onDropRejected,
        noClick: true
    });

    return (
        <div
            {...getRootProps()}
            className={`${
                hoverBorder &&
                'hover:!tw-border-gray-500 hover:!tw-border-dashed hover:!tw-border-2'
            }`}
            style={{
                border: isDragActive
                    ? '2px dashed green'
                    : isFocused && hoverBorder
                        ? '2px dashed grey'
                        : '2px solid transparent',
                borderRadius: '10px'
            }}
        >
            <input {...getInputProps()} ref={fileInput}/>
            {children}
        </div>
    );
};

export default DropZoneWrapper;
