import React, { useContext, useMemo } from 'react';
import { DateTime } from 'luxon';
import { Card, CardContent } from '@/components/ui/card';
import { useTranslation } from '@/composables/translation';
import { type Note as NoteInterface, type NoteEvent } from '@/types/api/note';
import { Collapsible, CollapsibleContent } from '@/components/ui/collapsible';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import {
    CalendarIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    DrawingPinFilledIcon,
    DrawingPinIcon, Link2Icon,
    PersonIcon
} from '@radix-ui/react-icons';
import { NoteTransferDialog } from '@/components/psj/notes/NoteTransferDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Badge } from '@/components/ui/badge';
import { useOverrideConfig } from '@/composables/override';
import _ from 'lodash';
import { ConfirmDialogButton } from '@/components/ConfirmDialogButton';
import { postDisableNote } from '@/composables/api';
import { useError } from '@/composables/error';
import { FolderContext } from '@/pages/psj/Folder';
import { toast } from 'react-toastify';
import { MetadataTags } from '@/components/psj/MetadataTags';

const BADGE_OPTIONS = [
    { variant: 'destructive', label: { fr: 'Erreur', en: 'Error' } },
    { variant: 'success', label: { fr: 'Envoyée', en: 'Sent' } },
    { variant: 'destructive', label: { fr: 'Erreur', en: 'Error' } },
    { variant: 'destructive', label: { fr: 'Erreur', en: 'Error' } }
] as const;

function NoteEventBadge({ event }: { event: NoteEvent }) {
    const { to } = useTranslation();
    const options = BADGE_OPTIONS[event.status];
    const date = DateTime.fromISO(event.executed_at);
    return (
        <div className="tw-flex tw-gap-2">
            <Badge variant={options.variant}>
                {to(options.label)}
            </Badge>
            <div className=" tw-text-sm tw-text-muted-foreground">
                {date.isValid && date.toFormat('ff')}
            </div>
        </div>
    );
}

export interface NoteOptions {
    pin?: boolean;
    delete?: boolean;
    transfer?: boolean;
}

interface Props {
    note: NoteInterface;
    visible?: boolean;
    pinned?: boolean;
    onChangeVisible?: React.Dispatch<boolean>;
    onChangePinned?: React.Dispatch<boolean>;
    options?: NoteOptions;
}

export function Note(props: Props) {
    const note = props.note;
    const { t, ct } = useTranslation('psj.notes');
    const override = useOverrideConfig();
    const { handleNetworkError } = useError();
    const folder = useContext(FolderContext);
    const options = _.defaults(
        { ...props.options },
        { pin: true, transfer: true, delete: true }
    );
    const isNeobot = note.origin.auth?.username === 'srvcneosaas@neolegal.ca';
    const events = useMemo(
        () => (props.note.event ?? [])
            .sort(
                (a, b) => (
                    DateTime.fromISO(a.executed_at)
                        .diff(DateTime.fromISO(b.executed_at), 'seconds')
                        .seconds
                )
            ),
        [props.note]
    );
    function handleDeleteNote() {
        return postDisableNote(props.note._id)
            .then(() => {
                const notes = (folder?.notes ?? []).filter(n => n._id !== props.note._id);
                folder?.setNotes(notes);
                toast(ct('messages.success'), { type: 'success' });
            })
            .catch(handleNetworkError);
    }
    return (
        <Card id={props.note._id}>
            <CardContent className="!tw-p-3 note-container">
                <div className="tw-flex">
                    <div className="tw-relative tw-top-[1px] tw-flex tw-items-center tw-gap-8">
                        <Tooltip>
                            <TooltipTrigger>
                                <div className="tw-flex tw-items-center tw-text-primary">
                                    {isNeobot
                                        ? <>
                                            <FontAwesomeIcon className="tw-mr-2" icon={faRobot} />
                                            Neobot
                                        </>
                                        : <>
                                            <PersonIcon className="tw-size-4 tw-mr-2" />
                                            {`${note.origin.auth?.username ?? 'N/A'}`}
                                        </>
                                    }
                                </div>
                            </TooltipTrigger>
                            <TooltipContent align="start">
                                {ct('user')}
                            </TooltipContent>
                        </Tooltip>
                        <Tooltip>
                            <TooltipTrigger>
                                <div className="tw-flex tw-items-center">
                                    <CalendarIcon className="tw-size-4 tw-mr-2" />
                                    {DateTime.fromISO(note.updated_at).toFormat('ff')}
                                </div>
                            </TooltipTrigger>
                            <TooltipContent align="start">
                                {ct('date')}
                            </TooltipContent>
                        </Tooltip>
                    </div>
                    <div className="print:tw-hidden tw-ml-auto tw-flex tw-items-center tw-gap-1">
                        {options.delete &&
                            <Tooltip>
                                <ConfirmDialogButton
                                    title={t('delete.title')}
                                    message={t('delete.message')}
                                    confirmText={ct('delete')}
                                    onConfirm={handleDeleteNote}
                                >
                                    <TooltipTrigger asChild>
                                        <Button
                                            variant="ghost" size="icon"
                                            className="!tw-rounded-full tw-text-destructive"
                                        >
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                            />
                                        </Button>
                                    </TooltipTrigger>
                                </ConfirmDialogButton>
                                <TooltipContent>
                                    {ct('remove')}
                                </TooltipContent>
                            </Tooltip>
                        }
                        {options.transfer &&
                            <NoteTransferDialog note={props.note} />
                        }
                        {options.pin && (
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button
                                        variant="ghost" size="icon"
                                        className="!tw-rounded-full tw-text-primary"
                                        onClick={() => props.onChangePinned?.(!props.pinned)}
                                    >
                                        {props.pinned
                                            ? <DrawingPinFilledIcon className="tw-size-6" />
                                            : <DrawingPinIcon className="tw-size-6" />}
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    {props.pinned ? t('unpin') : t('pin')}
                                </TooltipContent>
                            </Tooltip>
                        )}
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button
                                    variant="ghost" size="icon"
                                    className="!tw-rounded-full tw-text-primary"
                                    onClick={() => props.onChangeVisible?.(!props.visible)}
                                >
                                    {props.visible
                                        ? <ChevronDownIcon className="tw-size-6" />
                                        : <ChevronUpIcon className="tw-size-6" />}
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                                {props.visible ? t('hide') : t('show')}
                            </TooltipContent>
                        </Tooltip>
                    </div>
                </div>
                <Collapsible open={props.visible} onOpenChange={props.onChangeVisible}>
                    <CollapsibleContent>
                        <div className="tw-flex tw-flex-col tw-gap-2">
                            <div
                                className={cn(
                                    'tw-mt-1 tw-p-2 tw-rounded-sm tw-border tw-border-input',
                                    'tw-prose tw-max-w-none [&>p]:tw-my-0',
                                    !note.is_public && 'tw-bg-[#fff6d9]'
                                )}
                                dangerouslySetInnerHTML={{ __html: note.note }}
                            />
                            {!override?.is_customer_portal && note.origin.source !== 'portal' &&
                                <div className="tw-flex tw-flex-col tw-items-start tw-gap-1 empty:tw-hidden">
                                    {events.map((e, i) => <NoteEventBadge key={i} event={e}/>)}
                                </div>
                            }
                            {note.attachments && note.attachments.length > 0 &&
                                <div className="tw-flex tw-flex-col tw-gap-2">
                                    {note.attachments.map((f) =>
                                        <Button
                                            key={f._id} variant="link"
                                            className={cn(
                                                '!tw-w-fit !tw-h-fit tw-flex tw-items-center tw-gap-2',
                                                '!tw-px-0 !tw-py-1'
                                            )} asChild
                                        >
                                            <a href={f.url} target="_blank" rel="noreferrer">
                                                <Link2Icon className="tw-mt-0.5" />
                                                {f.name}
                                            </a>
                                        </Button>
                                    )}
                                </div>
                            }
                            {note.metadata && <MetadataTags value={note.metadata} />}
                        </div>
                    </CollapsibleContent>
                </Collapsible>
            </CardContent>
        </Card>
    );
}
