import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useTranslation } from '@/composables/translation';
import { Button } from '@/components/ui/button';
import {
    type CrudApi,
    CrudInputType,
    type CrudSchema,
    CrudTable
} from '@/components/ui/crud-table';
import { type CaseType, CreateCaseDialog } from '@/components/psj/case/CreateCaseDialog';
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BindProps } from '@/components/utils/BindProps';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { PlusIcon } from '@radix-ui/react-icons';
import { type User, type UserGroup } from '@/types/api/user';
import { createCase, getCalendarConfig, getFolders, getMacrosList, getOrgGroups, getOrgUsers } from '@/composables/api';
import { CaseStatusCell, DateCell, FolderIdCell } from '@/components/ui/cells';
import { type Party } from '@/types/api/party';
import { type CalendarConfig } from '@/types/api/calendar';
import { type GetFolderOptions } from '@/types/folder';
import { useQuery } from '@/composables/query';
import { DateTime } from 'luxon';
import type { Macro } from '@/types/api/macro';
import type { PaginationState } from '@tanstack/react-table';
import { toast } from 'react-toastify';
import { transformConfig } from '@/composables/calendar';

export function Dashboard() {
    const { t, ct } = useTranslation('dashboard');
    const location = useLocation();
    const query = useQuery<GetFolderOptions>();
    const crudApi = useRef<CrudApi>();
    const [selected, setSelected] = useState<{ folder_id: string; client?: Party }>();
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10
    });
    const [users, setUsers] = useState<User[]>([]);
    const [groups, setGroups] = useState<UserGroup[]>([]);
    const [macros, setMacros] = useState<Macro[]>([]);
    const [config, setConfig] = useState<CalendarConfig>();

    const queryParams = new URLSearchParams(location.search);
    const party_id = queryParams.get('party_id');
    const schema = useMemo<CrudSchema<CaseType>>(() => [
        {
            id: 'folder_id',
            type: CrudInputType.TEXT,
            name: t('table.id'),
            col: 6,
            create: true,
            columnDef: {
                id: 'folder_id',
                header: t('table.id'),
                accessorKey: 'folder_id',
                cell: FolderIdCell
            }
        },
        {
            id: 'name',
            type: CrudInputType.TEXT,
            name: t('table.name'),
            col: 6,
            columnDef: {
                id: 'name',
                header: t('table.name'),
                accessorKey: 'name'
            }
        },
        {
            id: 'status',
            type: CrudInputType.TEXT,
            name: t('table.status'),
            columnDef: {
                id: 'status',
                header: t('table.status'),
                accessorKey: 'status',
                cell: CaseStatusCell
            }
        },
        {
            id: 'main_client',
            type: CrudInputType.TEXT,
            name: t('table.client'),
            columnDef: {
                id: 'main_client',
                header: t('table.client'),
                accessorKey: 'main_client',
                cell: ({ cell }) => cell.getValue<Party | undefined>()?.fullname
            }
        },
        {
            id: 'updated_at',
            type: CrudInputType.TEXT,
            name: t('table.updated_at'),
            columnDef: {
                id: 'updated_at',
                header: t('table.updated_at'),
                accessorKey: 'updated_at',
                cell: DateCell
            }
        },
        {
            id: 'created_at',
            type: CrudInputType.TEXT,
            name: t('table.created_at'),
            columnDef: {
                id: 'created_at',
                header: t('table.created_at'),
                accessorKey: 'created_at',
                cell: DateCell
            }
        },
        {
            id: 'created_at_relative',
            type: CrudInputType.TEXT,
            update: false,
            create: false,
            columnDef: {
                id: 'created_at_relative',
                accessorKey: 'created_at',
                header: t('table.created_at'),
                cell: ({ cell }) => {
                    const date = DateTime.fromISO(cell.getValue<string>());
                    return date.isValid ? date.toRelative() : null;
                }
            }
        }
    ], []);

    const CreateDialogComponent = useMemo(
        () => BindProps(CreateCaseDialog, {
            folder_id: selected?.folder_id,
            client: selected?.client,
            users,
            groups,
            macros,
            calendarConfig: config
        }),
        [selected, users, groups, macros, config]
    );

    useEffect(() => {
        getOrgUsers().then((res) => setUsers(res.data));
        getOrgGroups().then((res) => setGroups(res.data));
        getMacrosList({ limit: 10000 }).then((res) => setMacros(res.data));
        getCalendarConfig().then((res) => setConfig(transformConfig(res.data)));
    }, []);

    return (
        <main className="tw-min-h-full !tw-p-4">
            <Card>
                <CardHeader>
                    <CardTitle>{t('title')}</CardTitle>
                </CardHeader>
                <CardContent>
                    <CrudTable<CaseType, '_id'>
                        useServerSearch
                        idKey="_id"
                        apiRef={crudApi}
                        schema={schema}
                        actions={({ row }) =>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button
                                        className="tw-text-muted-foreground"
                                        variant="ghost" size="icon"
                                        onClick={() => {
                                            setSelected({
                                                folder_id: row.original.folder_id,
                                                client: row.original.main_client
                                            });
                                            crudApi.current?.showCreateDialog();
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faFolderPlus}/>
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    {t('table.actions.create')}
                                </TooltipContent>
                            </Tooltip>
                        }
                        dialogComponent={CreateDialogComponent}
                        createButton={(props) =>
                            <Button
                                className="tw-text-success"
                                variant="outline" {...props}
                                onClick={() => {
                                    setSelected(undefined);
                                    props.onClick();
                                }}
                            >
                                <PlusIcon className="tw-mr-2"/>
                                {ct('create')}
                            </Button>
                        }
                        readDeps={[query]}
                        onRead={
                            (search) => getFolders({
                                ...query,
                                ...(search && {
                                    search_type: 'phrase',
                                    search_value: search,
                                    ...(party_id && {
                                        party_id,
                                        my_folders: false,
                                        or_filter: true,
                                        filter_by_partie: true,
                                        folder_id_regex: search,
                                        case_name: search
                                    }),
                                    ...(!party_id && { search_index: true })
                                }),
                                offset: pagination.pageIndex * pagination.pageSize,
                                limit: pagination.pageSize
                            })
                                .then((res) => {
                                    setRowCount(res.data.nb_rows);
                                    setPagination({
                                        pageSize: res.data.limit,
                                        pageIndex: pagination.pageIndex
                                    });
                                    return res.data.data.map(f => (
                                        { ...f, product: undefined }
                                    ));
                                })
                        }
                        onCreate={(value) => createCase(value).then(() => {
                            toast(ct('messages.success'), { type: 'success' });
                        })}
                        state={{ pagination }}
                        rowCount={rowCount}
                        onPaginationChange={setPagination}
                        manualPagination
                    />
                </CardContent>
            </Card>
        </main>
    );
}
