import axios, { type AxiosResponse } from 'axios';

import router from '@/router';
import { useUserStore } from '@/store/user';
import type {
    CreateUserDto, UpdateUserDto,
    GetUserSettingsResponse,
    PostForgotPasswordResponse,
    PostUserLoginResponse,
    PutResetPasswordResponse,
    PutUpdatePasswordResponse,
    PutUserSettingsResponse,
    User, UserGroup, CreateGroupDto, UpdateGroupDto, GetUsersOptions
} from '@/types/api/user';
import { HTTP_UNAUTHORIZED } from '@/types/http-codes';
import { DateTime } from 'luxon';
import { useDocumentStore } from '@/store/document';
import { DocumentStatus } from '@/types/document';
import {
    type NeoFormClientDto,
    type NeoFormCreateDto,
    type NeoFormData,
    type NeoFormStatus
} from '@/types/neoform';
import { type FormDataListResponse, type FormType } from '@/types/api/forms';
import { type CreateFileOptions, type File, type GetGenerateDocumentResponse } from '@/types/api/files';
import {
    type AppointmentScheduleData, type AppointmentScheduleOptions,
    type BookAppointmentTaken,
    type CalendarAvailabilityData,
    type CalendarConfig,
    type CalendarUpdateData,
    type CalendarUserDispo
} from '@/types/api/calendar';
import { type EmailReportDto } from '@/types/api/email';
import { type FormSaveConfig } from '@/components/neoform/context/NeoFormContext';
import { type PostPinNoteDto, type GetNotesOptions, type Note, type PostAddNoteDto } from '@/types/api/note';
import {
    type GetCaseDto,
    type Case,
    type GetFolderOptions,
    type GetExternalFoldersOptions,
    type ExternalCase
} from '@/types/folder';
import {
    type CreateProcessDto,
    type GetNextStepsOptions, type GetProcessOptions,
    type NextStepInstance,
    type Process,
    type UpdateProcessDto
} from '@/types/api/process';
import { type Paginated } from '@/types/api/pagination';
import {
    type CreateProductDto,
    type GetProductOptions,
    type Product,
    type UpdateProductDto
} from '@/types/api/product';
import {
    type CreatePartyDto, type GetConflictDto,
    type GetConflictOptions,
    type GetPartyOptions, type GetPartyTypeOptions,
    type Party, type PartyType,
    type UpdatePartyDto
} from '@/types/api/party';
import { type GetMacrosOptions } from '@/types/api/macro';
import { type CaseType } from '@/components/psj/case/CreateCaseDialog';
import { type GetViewsOptions, type PostCreateViewDto, type PutUpdateViewDto, type View } from '@/types/api/views';
import { type PaginatedApiResponse } from '@/types/api/response';
import { guessFileType } from '@/composables/utils';

export const instance = axios.create({
    baseURL: import.meta.env.VITE_API_URL
});

instance.interceptors.response.use(undefined, async(error) => {
    const token = useUserStore.getState().token;
    const response = error?.response;
    if (response?.status === HTTP_UNAUTHORIZED && token) {
        await router.navigate('/logout');
    }
    return await Promise.reject(error);
});

instance.interceptors.request.use((config) => {
    const userState = useUserStore.getState();
    if (userState.token) config.headers.Authorization = `Bearer ${userState.token}`;
    if (userState.user?.user_id) {
        if (typeof config.params === 'object') {
            config.params.auth_id = userState.user?.user_id;
        } else {
            config.params = { auth_id: userState.user?.user_id };
        }
    }
    return config;
});

export function login(org: string, username: string, password: string, otp: string) {
    return instance.post<PostUserLoginResponse>(
        '/v1/login',
        {
            username,
            password,
            org,
            otp
        }
    );
}

export function loginPsj(token: string) {
    return instance.get(`/v1/psj/login/${token}`);
}

export function getTemplateSettings(token?: string) {
    return instance.get('/v1/settings/templates', {
        headers: {
            ...(token && {
                Authorization: `Bearer ${token}`
            })
        }
    });
}

export function sendForgotPasswordLink(org: string, email: string) {
    return instance.post<PostForgotPasswordResponse>(
        '/v1/forgot-password',
        {
            email,
            org
        }
    );
}

export function genForgotPasswordLinkForParty(org: string, email: string) {
    return instance.post<PostForgotPasswordResponse>(
        '/v1/customer/login/password/forgot/by_admin',
        {
            email,
            org,
            local_reset: true
        }
    );
}

export function resetPassword(newPassword: string, token: string) {
    return instance.put<PutResetPasswordResponse>(
        '/v1/reset-user-password',
        {
            new_password: newPassword
        },
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );
}

export function updatePassword(oldPassword: string, newPassword: string) {
    const user = useUserStore.getState().user;
    return instance.put<PutUpdatePasswordResponse>(
        '/v1/update-user-password',
        {
            id: user?.user_id,
            old_password: oldPassword,
            new_password: newPassword
        }
    );
}

export function postUpdateUser(user: UpdateUserDto) {
    return instance.put<PutUserSettingsResponse>(
        '/v1/update-user',
        user
    );
}

export function getUserSettings() {
    const user = useUserStore.getState().user;
    return instance.get<GetUserSettingsResponse>(
        `/v1/user/${user?.user_id ?? ''}`
    );
}

export function getFormsList(filter: any, limit = 10, page = 1) {
    return instance.get<any, AxiosResponse<FormDataListResponse>>('/v1/form-data-list', {
        params: {
            filter: JSON.stringify(filter),
            limit,
            page
        }
    });
}

export function getForms() {
    const user = useUserStore.getState().user;
    return instance.get<FormType[]>(`/v1/form-org/${user?.org_id ?? ''}`);
}

export function postCreateForm(data: NeoFormCreateDto) {
    const user = useUserStore.getState().user;
    return instance.post('/v1/form/creator', {
        form_json: {},
        org_id: user?.org_id,
        auth_id: user?.user_id,
        ...data
    });
}

export function getInvoiceInfo(start: DateTime, end: DateTime) {
    return instance.get('/v1/invoice-infos', {
        params: {
            start_date: start.toISODate(),
            end_date: end.toISODate()
        }
    });
}

export function postFormData(
    publicForm: boolean,
    taskId: string,
    data: any,
    meta: any,
    config?: FormSaveConfig
) {
    const user = useUserStore.getState().user;
    return instance.post(publicForm ? '/v1/form-data-public' : '/v1/form-data', {
        task_id: taskId,
        auth_id: user?.user_id ?? '',
        data,
        meta,
        document_type: config?.document_type
    });
}

export function generateDocument(taskId: string, docType: string, is_public: boolean = false,
    force_gen: boolean = false) {
    return instance.get<GetGenerateDocumentResponse>(
        is_public
            ? `/v1/generate-document-public/${taskId}`
            : `/v1/generate-document/${taskId}`,
        {
            params: {
                document_type: docType,
                force_gen
            }
        }
    );
}

export function getDocumentStatus() {
    const documents = useDocumentStore.getState().documents
        .filter(d => Object.values(d.document_types)
            .some(({ status }) =>
                status === DocumentStatus.PENDING ||
                status === DocumentStatus.PROCESSING));
    const promises = documents.map(d => instance.get(`/v1/doc-infos/${d.task_id}`));
    return Promise.all(promises)
        .then((res) => res.map<[string, AxiosResponse<any>]>(
            (data, i) => [documents[i].task_id, data.data])
        );
}

export function getFormData(name?: string, task_id?: string, token?: string) {
    const url = token ? `/v1/form-public/${token}` : `/v1/form-data-by-id/${task_id}`;
    return instance.get(url, {
        params: {
            form_name: name
        }
    });
}

export function postUploadFile(blob: Blob, params: CreateFileOptions) {
    const file = new File([blob], blob.name, {
        type: guessFileType(blob.name)
    });
    return instance.postForm<File>('/v1/storage/file', { file }, { params });
}

export function getFileData(token: string) {
    return instance.get('/v1/get-file', {
        params: {
            path: token,
            output: 'base64'
        }
    });
}

export function getFormClients(task_id: string) {
    const user = useUserStore.getState().user;
    return instance.post('/v1/form/list-form-clients', {
        auth_id: user?.user_id,
        task_id,
        org_id: user?.org_id
    });
}

export function postFormClients(task_id: string, clients: NeoFormClientDto[]) {
    const store = useUserStore.getState();
    const user = store.user;
    return instance.post('/v1/form/form-clients', {
        auth_id: user?.user_id,
        task_id,
        org_id: user?.org_id,
        lang: store.lang,
        clients_data: clients
    });
}

export function postFormClientSendEmails(task_id: string, client_ids: string[]) {
    const store = useUserStore.getState();
    const user = store.user;
    return instance.post<EmailReportDto>('/v1/form/form-clients/send', {
        auth_id: user?.user_id,
        task_id,
        org_id: user?.org_id,
        lang: store.lang,
        clients_id: client_ids
    });
}

export function getEmailQueueStatus(task_id: string, queue_ids: string[]) {
    const user = useUserStore.getState().user;
    return instance.post('/v1/form/form-clients/email-status', {
        auth_id: user?.user_id,
        org_id: user?.org_id,
        task_id,
        email_queue: queue_ids
    });
}

export function postFormStatus(task_id: string, status: NeoFormStatus) {
    const user = useUserStore.getState().user;
    return instance.post('/v1/form/update/status', {
        auth_id: user?.user_id,
        org_id: user?.org_id,
        task_id,
        status
    });
}

export function getGeneratedField(endpoint: string, task_id: string) {
    return instance.get(`${endpoint}/${task_id}`, {
        params: {
            task_id
        }
    });
}

export function postCompleteFormData(task_id: string, form_data: NeoFormData, is_public: boolean) {
    const user = useUserStore.getState().user;
    return instance.post(is_public ? '/v1/public-complete-form-data' : '/v1/complete-form-data', {
        auth_id: user?.user_id,
        org_id: user?.org_id,
        task_id,
        form_data
    });
}

export function getCaseInfo(case_or_folder_id: string) {
    return instance.get<GetCaseDto>('/v1/psj/case', { params: { case_or_folder_id } });
}

export function getNextSteps(params: Paginated<GetNextStepsOptions>) {
    return instance.get<NextStepInstance[]>(
        '/v1/psj/next_step/list',
        { params }
    );
}

export function postAddNote(note: PostAddNoteDto) {
    return instance.post<{ note: Note }>('/v1/psj/note', note);
}

export function addParty(party: any) {
    const user = useUserStore.getState().user;
    return instance.post('/v1/parties/add-party', {
        auth_id: user?.user_id,
        org_id: user?.org_id,
        ...party
    });
}

export function getOrgUsers(params?: GetUsersOptions) {
    return instance.post<User[]>('/v1/users', {}, { params });
}

export function getOrgGroups() {
    return instance.get<UserGroup[]>('/v1/group/list');
}

export function getCalendarConfig() {
    return instance.get<CalendarConfig>('/calendar/v1/config');
}

export function getCalendarDispos(start_date: DateTime, end_date: DateTime) {
    const user = useUserStore.getState().user;
    return instance.post<CalendarUserDispo[]>(
        '/calendar/v1/disponibilities',
        {
            auth_id: user?.user_id,
            st_date: start_date.toISO({ suppressMilliseconds: true }),
            nd_date: end_date.toISO({ suppressMilliseconds: true })
        }
    );
}

export function postCalendarDispos(data: CalendarUpdateData) {
    return instance.post('/calendar/v1/disponibilities/update', data);
}

export function getCalendarAvailabilities(data: any, token?: string) {
    const timezone = DateTime.local().zoneName;
    return instance.post<CalendarAvailabilityData>(
        `/calendar/v1/availabilities${token ? '/public' : ''}`,
        {
            timezone,
            ...data
        }
    );
}

export function postBookAppointment(taken: BookAppointmentTaken, is_public?: boolean) {
    return instance.post(`/calendar/v1/appointment/book${is_public ? '/public' : ''}`, taken);
}

export function postUpdateAppointment(taken: Partial<BookAppointmentTaken> & { id: string }) {
    return instance.post('/calendar/v1/appointment/update', taken);
}

export function deleteAppointment(id: string) {
    return instance.post('/calendar/v1/appointment/delete', { id });
}

export function postAppointmentUrl(data: any) {
    const timezone = DateTime.local().zoneName;
    return instance.post(
        '/calendar/v1/get-public-link',
        {
            timezone,
            ...data
        }
    );
}

export function getAppointmentSchedule(options: AppointmentScheduleOptions) {
    return instance.post<AppointmentScheduleData>('/calendar/v1/appointment/schedule', {
        st_date: options.start_date?.toISO({ suppressMilliseconds: true }),
        nd_date: options.end_date?.toISO({ suppressMilliseconds: true }),
        users: options.users,
        ticket_id: options.ticket_id
    });
}

export function postRequestAccessCode(token: string) {
    return axios.post('/v1/2fa/access-code/request', undefined, {
        baseURL: import.meta.env.VITE_API_URL,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export function postValidateAccessCode(token: string, code: string) {
    return axios.post('/v1/2fa/access-code/validate', {
        user_code: code
    }, {
        baseURL: import.meta.env.VITE_API_URL,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export function postValidateJwt(token: string) {
    return axios.post(
        '/v1/2fa/access-code/validate-jwt', {},
        {
            baseURL: import.meta.env.VITE_API_URL,
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );
}

export function postCreateUser(user: CreateUserDto) {
    return instance.post('/v1/user', user);
}

export function putUpdateGroup(group: UpdateGroupDto) {
    return instance.put('/v1/group/update', group);
}

export function postCreateGroup(group: CreateGroupDto) {
    return instance.post('/v1/group/new', group);
}

export function postGenerateWord(pdf_url: string) {
    const user = useUserStore.getState().user;
    return instance.post<Blob>(
        '/v1/generate-word',
        {
            auth_id: user?.user_id,
            document_pdf_url: pdf_url
        },
        {
            responseType: 'blob'
        }
    );
}

export function getFile(url: string) {
    return instance.get<Blob>(
        '',
        {
            baseURL: url,
            responseType: 'blob'
        }
    );
}

export function getDocuments(folder_id: string) {
    return instance.get('/v1/psj/case/files/', { params: { id: folder_id } });
}

export function postUpdateDocument({ _id, ...file }: any) {
    return instance.post(`/v1/storage/file/${_id}`, file);
}

export function deleteDocuments(file_ids: string[]) {
    return instance.delete('/v1/storage/delete/files', { data: file_ids });
}

export function getProcessList(params?: Paginated<GetProcessOptions>) {
    return instance.get<Process[]>('/v1/psj/process/list', { params });
}

export function getFolders(params?: Paginated<GetFolderOptions>) {
    return instance.get<PaginatedApiResponse<Case>>('/v1/psj/case/folders', { params });
}

export function postTransferNote(note_id: string, case_id: string) {
    return instance.post('/v1/psj/note/transfer', { _id: note_id, case: { _id: case_id } });
}

export function postCreateNextStepMulti(nextsteps: NextStepInstance[]) {
    return instance.post('/v1/psj/next_step/multi', nextsteps);
}

export function postCreateProcess(process: CreateProcessDto) {
    return instance.post('/v1/psj/process', process);
}

export function putUpdateProcess(process: UpdateProcessDto) {
    return instance.put('/v1/psj/process', process);
}

export function postCreateProduct(product: CreateProductDto) {
    return instance.post('/v1/psj/product', product);
}

export function putUpdateProduct(product: UpdateProductDto) {
    return instance.put('/v1/psj/product', product);
}

export function getProductList(params?: Paginated<GetProductOptions>) {
    return instance.get<Product[]>('/v1/psj/product/list', { params });
}

export function getOrgParties(params?: Paginated<GetPartyOptions>) {
    return instance.get<PaginatedApiResponse<Party>>('/v1/psj/parties/list', { params });
}

export function postCreateParty(party: CreatePartyDto) {
    return instance.post('/v1/psj/parties', {
        ...party,
        phone: party.phone || undefined,
        email: party.email || undefined
    });
}

export function putUpdateParty(party: UpdatePartyDto) {
    return instance.put('/v1/psj/parties', party);
}

export function getClientConflict(params?: GetConflictOptions) {
    return instance.get<GetConflictDto>('/v1/psj/case/conflict-of-interest', { params });
}

export function getNotesList(params?: Paginated<GetNotesOptions>) {
    return instance.get<{ total: number; count: number; notes: Note[] }>('/v1/psj/note/list', { params });
}

export function getMacrosList(params?: Paginated<GetMacrosOptions>) {
    return instance.get('/v1/psj/macro/search', { params });
}

export function getPartyTypesList(params?: Paginated<GetPartyTypeOptions>) {
    return instance.get<PartyType[]>('/v1/psj/party_type/list', { params });
}

export function createCase(value: Omit<CaseType, '_id'>) {
    const { user, lang } = useUserStore.getState();
    return postAddNote({
        case: {
            folder_id: !value.disable_fid ? value.folder_id : undefined,
            create_new_case: true,
            client_id: value.client?.client_id,
            status: 'created',
            name: value.name,
            groups: value.groups as any,
            product: {
                _id: value.product?._id ?? '',
                process_id: value.product?.process?._id ?? null,
                ns_list: (value.ns_list ?? []).map((ns, index) => ({
                    ...ns,
                    index,
                    parent: ns.parent && typeof ns.parent === 'object'
                        ? ns.parent._id
                        : ns.parent
                })),
                price: value.product?.price ?? '',
                fees: value.product?.fees ?? ''
            },
            parties: value.client
                ? [{
                    _id: value.client._id,
                    type: 'client'
                }]
                : [],
            assign: {
                type: 'user',
                _id: user?.user_id ?? '',
                name: user?.fullname ?? ''
            },
            nextstep: value.product?.process.ns_list?.[0]
        },
        hidden: true,
        note: '',
        type: 'create',
        lang,
        is_public: false,
        active: true
    });
}

export function postPinNote(data: PostPinNoteDto) {
    return instance.post('/v1/psj/note/pin', data);
}

export function getCaseList(folder_id: string) {
    return instance.get<Case[]>('/v1/psj/case/list', {
        params: {
            folder_id
        }
    });
}

export function getViews(params?: Paginated<GetViewsOptions>) {
    return instance.get<View[]>('/v1/psj/view/list', { params });
}

export function putUpdateView(data: PutUpdateViewDto) {
    return instance.put('/v1/psj/view', data);
}

export function postCreateView(data: PostCreateViewDto) {
    return instance.post('/v1/psj/view', data);
}

export function deleteView(view_id: string) {
    return instance.delete(`/v1/psj/view/${view_id}`);
}

export function postDisableNote(note_id: string) {
    return instance.post('/v1/psj/note/disable', note_id);
}

export function getExternalFolders(params?: Paginated<GetExternalFoldersOptions>) {
    return instance.get<PaginatedApiResponse<ExternalCase>>('/v1/psj/case/folders/external', { params });
}
